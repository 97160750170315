<template>
  <vxe-table border stripe max-height="300px" :data="productList">
    <vxe-table-column min-width="120" field="productType" title="产品类型">
        <template #default="{ row }">
          <span v-if="row.productType === 'GIVE'">赠送产品</span>
          <span v-if="row.productType === 'THROW'">投放产品</span>
          <span v-if="row.productType === 'ENTRY'">进场产品</span>
          <span v-if="row.productType === 'CONFER'">会议用酒</span>
          <span v-if="row.productType === 'PROCUREMENT'">采购商品</span>
          <span v-if="row.productType === 'BANQUET'">宴席用酒</span>
          <span v-if="row.productType === 'MEETING_WINE'">会议用酒</span>
          <span v-if="row.productType === 'GIVING_PRODUCT'">赠送产品</span>
          <span v-if="row.productType === 'GIFT'">礼品</span>
        </template>
      </vxe-table-column>
    <vxe-column field="productCode" title="产品编码" show-overflow></vxe-column>
    <vxe-column field="productName" title="产品名称" show-overflow></vxe-column>

  </vxe-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value() {
      this.productList = this.value;
    },
  },
  data() {
    return {
      productList: [],
    };
  },
};
</script>
